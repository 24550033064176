<template>
  <header class="s-header">
    <!-- Layout desktop do menu -->
    <section class="s-header__desktop">
      <!-- Logo do Alfa -->
      <Nuxt-link @click="refreshPage" style="cursor: pointer;">
        <img class="s-header__desktop__icon" src="@/assets/img/home/logo.png" alt="Alfa Consignado" />
      </Nuxt-link>
      <!-- Menu principal -->
      <nav class="s-header__desktop__routes">
        <template v-for="(link, i) in pathLinks" :key="i">
          <a v-if="link.href !== '/'" :href="link.href" class="s-header__desktop__routes__route">
            {{ link.name }}
          </a>
        </template>
      </nav>

      <!-- Botões de ação do header  -->
      <div class="s-header__desktop__buttons">
        <!-- Botão de login -->
        <a href="/minha-conta">
          <button class="s-header__desktop__buttons__button" type="button">Login</button>
        </a>
      </div>
    </section>

    <!-- Layout mobile do menu -->
    <section class="s-header__mobile">
      <!-- Logo do Alfa -->
      <img class="s-header__desktop__icon" src="@/assets/img/home/logo.png" alt="Alfa Consignado" />

      <!-- Titulo do menu -->
      <div class="s-header__mobile__title" @click="handleMenu">
        <IconMenuHamburguer class="s-header__mobile__title__icon" width="90" height="90" />
        <span class="s-header__mobile__title__text" tag="text-support">MENU</span>
      </div>

      <!-- Menu header da aplicação -->
      <AtomMenu class="s-header__mobile__menu" :outshow="show" :links="pathLinks" @closed="handleMenu()">
        <a class="s-header__mobile__menu__link" href="/minha-conta"><button class="s-header__mobile__menu__link__button" type="button">Login</button></a>
      </AtomMenu>
    </section>
  </header>
</template>

<script>
// Icones
import IconExit from '~/components/shared/icons/IconExit.vue'
import IconMenuHamburguer from '~/components/shared/icons/IconMenuHamburguer.vue'

// Componentes
import ButtonDefault from '@/components/shared/buttons/ButtonDefault/ButtonDefault.vue'
import TextTypography from '@/components/shared/typography/TextTypography.vue'
import AtomMenu from '@/components/atoms/AtomMenu/AtomMenu.vue'

export default {
  name: 'FlexHeader',
  components: {
    // Icones
    IconExit,
    IconMenuHamburguer,
    // Componentes
    ButtonDefault,
    TextTypography,
    AtomMenu,
  },
  props: {
    pathLinks: {
      type: Array,
      default() {
        return [
          { name: 'Pagina 1', href: '#' },
          { name: 'Pagina 2', href: '#' },
        ]
      },
      required: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    handleMenu() {
      this.show = !this.show

      if (this.show) {
        return (document.body.style.overflow = 'hidden')
      }

      return (document.body.style.overflow = 'unset')
    },

    refreshPage() {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      } else {
        this.$router.go({ path: '/' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.s-header {
  display: grid;
  place-items: start center;
  place-content: start stretch;
  background-color: #006982;

  &__desktop {
    display: grid;
    grid-template-columns: 0.2fr 0.7fr 0.5fr;
    place-items: center;
    padding: $spacing_16 140px;
    width: 100%;
    margin-inline: auto;

    @media (max-width: 1279px) {
      display: none;
    }

    @media (max-width: 1743px) {
      grid-template-columns: auto 1fr auto;
      max-width: 1435px;
      padding: $spacing_16 $spacing_32;
    }

    @media (max-width: 1600px) {
      max-width: 1280px;
    }

    @media (min-width: 2000px) {
      max-width: 2000px;
    }

    &__icon {
      width: auto;
      height: 95px;

      @media (max-width: 1600px) {
        height: 78px;
      }
    }

    &__routes {
      display: grid;
      grid-auto-flow: column;
      place-content: center;
      column-gap: 32px;
      list-style-type: none;
      margin: 0;
      padding: 0;

      @media (max-width: 1600px) {
        column-gap: 30px;
      }

      &__route {
        text-decoration: none;
        font-family: $montserrat-light;
        font-size: 24px;
        color: $alfa-sec;
        opacity: 0.7;

        @media (max-width: 1600px) {
          font-size: 20px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    &__buttons {
      display: grid;
      width: 100%;
      justify-content: end;
      column-gap: $spacing_16;

      &__link {
        display: grid;
        grid-auto-flow: column;
        place-content: center;
        place-items: center;
        column-gap: $spacing_8;

        &__label {
          text-decoration: none;
          font-family: $montserrat-medium;
          font-size: 24px;
          color: $alfa-sec;
          cursor: pointer;

          @media (max-width: 1600px) {
            font-size: 20px;
          }
        }
      }

      &__button {
        cursor: pointer;
        padding: 10px 42px;
        text-decoration: none;
        font-family: $montserrat-extra-bold;
        font-size: 24px;
        color: $alfa-qua;
        outline: none;
        background-color: $alfa-ter;
        border: 0;
        border-radius: 25px;

        @media (max-width: 1600px) {
          font-size: 20px;
          padding: 10px 32px;
        }

        &:hover {
          background-color: #ffd66c;
        }
      }
    }
  }

  &__mobile {
    display: grid;
    grid-template-columns: 1fr auto;
    place-self: start stretch;
    column-gap: $spacing_64;
    padding: 15px 18px 12px;

    @media (min-width: 1280px) {
      display: none;
    }

    &__title {
      display: grid;
      grid-template-rows: 57px auto;
      place-content: start stretch;
      place-items: center;

      &__text {
        font-family: $montserrat-light;
        font-size: $alfa-size-apoio;
        line-height: $alfa-line-apoio;
        color: $alfa-sec;
      }
    }

    &__menu {
      &__link {
        text-align: center;

        &__button {
          cursor: pointer;
          width: 80%;
          margin: auto auto $spacing_32;
          padding: 10px 0;
          text-decoration: none;
          font-family: $montserrat-extra-bold;
          font-size: 22px;
          line-height: 28px;
          color: $alfa-qua;
          outline: none;
          background-color: $alfa-ter;
          border: 0;
          border-radius: 25px;
        }
      }
    }
  }
}
</style>
